import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import api from "@helper/axios";
import { useGlobal } from "../store/global";

const Inicio = () => import("@views/Main/Inicio.vue");
const Descubrenos = () => import("@views/Main/Descubrenos.vue");
const Donaciones = () => import("@views/Main/Donaciones.vue");

const DonacionesVoluntarios= () => import("@views/Main/RegistroVoluntarios.vue");
const DonacionesMedicamentos = () => import("@views/Main/RegistroDonaMedicamentos.vue");
const DonacionesBuscar = () => import("@views/Main/RegistroBuscaMedicamento.vue");

const IntercambioSaludable = () => import("@views/Main/IntercambioSaludable.vue");

const Cuidadores = () => import("@views/Main/Cuidadores.vue");
const Informate = () => import("@views/Main/Informate.vue");
const ArticleDetalle = () => import("@views/Main/ArticleDetalle.vue");
const InspiracionDetalle = () => import("@views/Main/InspirarteDetalle.vue");

const Registro = () => import("@views/Main/Registro.vue");
const ForgotPassword = () => import("@views/Main/ForgotPassword.vue");
const Login = () => import("@views/Main/Login.vue");
const PoliticaPrivacidad = () => import("@views/Main/PoliticaPrivacidad.vue");
const Inspiracion = () => import("@views/Main/Inspiracion.vue");
const Paciente = () => import("@views/Main/Paciente.vue");
const Bienestar = () => import("@views/Main/Bienestar.vue");
const ArteTerapia = () => import("@views/Main/ArteTerapia.vue");
const AutoCuidado = () => import("@views/Main/AutoCuidado.vue");
const Comunicarte = () => import("@views/Main/Comunicarte.vue");
const Construccion  = () => import("@views/Construccion.vue");

const Investigacion = () => import("@views/Main/Investigacion.vue");
const Salud = () => import("@views/Main/Salud.vue");

const Page404 = () => import("@views/Pages/error/404.vue");
const Page500 = () => import("@views/Pages/error/500.vue");
const PageMaintenance = () => import("@views/Pages/error/maintenance.vue");


const Dashboard  = () => import("@views/Home/Inicio.vue");
const Usuario  = () => import("@views/Usuarios/Usuario.vue");
const UsuarioAdd  = () => import("@views/Usuarios/UsuarioAdd.vue");
const UsuarioDetail = () => import("@views/Usuarios/UsuarioDetail.vue");
const UsuarioEdit = () => import("@views/Usuarios/UsuarioEdit.vue");
const UsuarioPerfilEdit = () => import("@views/Usuarios/UsuarioPerfilEdit.vue");

const Contacto  = () => import("@views/Comunicarte/Comunicarte.vue");
const ContactoDetail  = () => import("@views/Comunicarte/ComunicarteDetail.vue");

const Publicaciones  = () => import("@views/Publicaciones/Publicaciones.vue");
const PublicacionesAdd  = () => import("@views/Publicaciones/PublicacionesAdd.vue");
const PublicacionesDetail = () => import("@views/Publicaciones/PublicacionesDetail.vue");
const PublicacionesEdit = () => import("@views/Publicaciones/PublicacionesEdit.vue");

const Solicitudes  = () => import("@views/Inspirarte/Solicitudes.vue");
const Inspirarte  = () => import("@views/Inspirarte/Inspirarte.vue");
const InspirarteAdd  = () => import("@views/Inspirarte/InspirarteAdd.vue");
const InspirarteDetail = () => import("@views/Inspirarte/InspirarteDetail.vue");
const InspirarteEdit = () => import("@views/Inspirarte/InspirarteEdit.vue");
const InspirarteSolicitudes  = () => import("@views/Inspirarte/InspirarteSolicitudes.vue");

const Medico  = () => import("@views/Medicos/Medico.vue");
const MedicoAdd  = () => import("@views/Medicos/MedicoAdd.vue");
const MedicoDetail = () => import("@views/Medicos/MedicoDetail.vue");
const MedicoEdit = () => import("@views/Medicos/MedicoEdit.vue");


const Testimonio  = () => import("@views/Testimonios/Testimonio.vue");
const TestimonioAdd  = () => import("@views/Testimonios/TestimonioAdd.vue");
const TestimonioDetail = () => import("@views/Testimonios/TestimonioDetail.vue");
const TestimonioEdit = () => import("@views/Testimonios/TestimonioEdit.vue");


const Pais  = () => import("@views/Ubicacion/Pais.vue");
const PaisAdd  = () => import("@views/Ubicacion/PaisAdd.vue");
const PaisEdit = () => import("@views/Ubicacion/PaisEdit.vue");

const Estado  = () => import("@views/Ubicacion/Estado.vue");
const EstadoAdd  = () => import("@views/Ubicacion/EstadoAdd.vue");
const EstadoEdit = () => import("@views/Ubicacion/EstadoEdit.vue");

const Municipio  = () => import("@views/Ubicacion/Municipio.vue");
const MunicipioAdd  = () => import("@views/Ubicacion/MunicipioAdd.vue");
const MunicipioEdit = () => import("@views/Ubicacion/MunicipioEdit.vue");

const Parroquia  = () => import("@views/Ubicacion/Parroquia.vue");
const ParroquiaAdd  = () => import("@views/Ubicacion/ParroquiaAdd.vue");
const ParroquiaEdit = () => import("@views/Ubicacion/ParroquiaEdit.vue");

const Ciudad  = () => import("@views/Ubicacion/Ciudad.vue");
const CiudadAdd  = () => import("@views/Ubicacion/CiudadAdd.vue");
const CiudadEdit = () => import("@views/Ubicacion/CiudadEdit.vue");

const Medicamento  = () => import("@views/Medicamentos/Medicamento.vue");
const MedicamentoAdd  = () => import("@views/Medicamentos/MedicamentoAdd.vue");
const MedicamentoDetail = () => import("@views/Medicamentos/MedicamentoDetail.vue");
const MedicamentoEdit = () => import("@views/Medicamentos/MedicamentoEdit.vue");


import { useLoggedUser } from "@store/user.js"
var appname = " - Zuperarte ";

const routes = [
  // Routes
  {
    path: "/",
    name: "Construccion",
    component: Construccion,
    meta: { title: "página web en construcción" + appname }
  },
  
  {
    path: "/inicio",
    name: "Inicio",
    component: Inicio,
    meta: { title: "Inicio " + appname },
  },
   // layout/error
  // default page 404
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "404" + appname, },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Error" + appname, },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: PageMaintenance,
    meta: {
      title: "Mantenimiento" + appname },
  },
  {
    path: "/descubrenos",
    name: "Descubrenos",
    component: Descubrenos,
    meta: { title: "Descúbrenos  " + appname },
  },
  {
    path: "/donaciones",
    name: "Donaciones",
    component: Donaciones,
    meta: { title: "Donaciones " + appname },
  },
  {
    path: "/intercambio",
    name: "IntercambioSaludable",
    component: IntercambioSaludable,
    meta: { title: "Intercambio Saludable " + appname },
  },
  
  {
    path: "/buscar_medicamentos",
    name: "DonacionesBuscar",
    component: DonacionesBuscar,
    meta: { title: "Buscar Medicamento" + appname },
  },
  {
    path: "/medicamentos",
    name: "DonacionesMedicamentos",
    component: DonacionesMedicamentos,
    meta: { title: "Medicamentos " + appname },
  },
  {
    path: "/voluntarios",
    name: "DonacionesVoluntarios",
    component: DonacionesVoluntarios,
    meta: { title: "Voluntarios " + appname },
  },
  
 {
    path: "/cuidadores",
    name: "Cuidadores",
    component: Cuidadores,
    meta: { title: "Cuidadores " + appname },
  },
  {
    path: "/informarte",
    name: "Informate",
    component: Informate,
    meta: { title: "Informate " + appname },
  },
  {
    path: "/:id:/:slug",
    name: "ArticleDetalle",
    component: ArticleDetalle,
    params: true,
    meta: { title: "Informate " + appname },
  },
   {
    path: "/inspiracion/:id:/:slug",
    name: "InspiracionDetalle",
    component: InspiracionDetalle,
    params: true,
    meta: { title: "Inspirarte " + appname },
  },
  {
    path: "/investigacion",
    name: "Investigacion",
    component: Investigacion,
    meta: { title: " Investigación  " + appname },
  },
  {
    path: "/salud",
    name: "Salud",
    component: Salud,
    meta: { title: "salud " + appname },
  },
   {
    path: "/inspiracion",
    name: "Inspiracion",
    component: Inspiracion,
    meta: { title: "Inspirarte " + appname },
  }, 
  {
    path: "/arte_terapia",
    name: "ArteTerapia",
    component: ArteTerapia,
    meta: { title: "ArteTerapia " + appname },
  }, 
  {
    path: "/auto_cuidado",
    name: "AutoCuidado",
    component: AutoCuidado,
    meta: { title: "AutoCuidado " + appname },
  }, 
  {
    path: "/paciente_activo",
    name: "Paciente",
    component: Paciente,
    meta: { title: "Paciente Activo " + appname },
  }, 
  {
    path: "/bienestar",
    name: "Bienestar",
    component: Bienestar,
    meta: { title: "Bienestar" + appname },
  }, 
  {
    path: "/comunicarte",
    name: "Comunicarte",
    component: Comunicarte,
    meta: { title: "Comunicarte" + appname },
  }, 
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Iniciar Session " + appname },
  },
  {
    path: "/recuperar_contrasena",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: "Olvidé mi contraseña" + appname},
  },
  {
    path: "/registro",
    name: "Registro",
    component: Registro,
    meta: { title: "Registro " + appname },
  },
  {
    path: "/politica_de_privacidad",
    name: "PoliticaPrivacidad",
    component: PoliticaPrivacidad,
    meta: { title: "Política de privacidad" + appname },
  },
  {
    path: "/home",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Inicio " +   appname },
  },
  {
    path: "/usuario",
    name: "Usuario",
    component: Usuario,
    meta: { title: "Usuarios " +   appname },
  },
  {
    path: "/usuario/detalle/:id",
    name: "UsuarioDetail",
    component: UsuarioDetail,
    meta: { title: "Usuario Detalle " + appname },
  },
  {
    path: "/usuario/agregar",
    name: "UsuarioAdd",
    component: UsuarioAdd,
    meta: { title: "Usuario Registrar " + appname },
  },
  {
    path: "/usuario/editar/:id",
    name: "UsuarioEdit",
    component: UsuarioEdit,
    meta: { title: "Usuario Editar " + appname },
  },
   {
    path: "/usuario/perfil/",
    name: "UsuarioPerfilEdit",
    component: UsuarioPerfilEdit,
    meta: { title: "Perfil Editar " + appname },
  }, 
  {
    path: "/publicacion",
    name: "Publicaciones",
    component: Publicaciones,
    meta: { title: "Publicaciones " +   appname },
  },
  {
    path: "/publicacion/agregar",
    name: "PublicacionesAdd",
    component: PublicacionesAdd,
    meta: { title: "Publicaciones Registrar " + appname },
  },
  {
    path: "/publicacion/editar/:id",
    name: "PublicacionesEdit",
    component: PublicacionesEdit,
    meta: { title: "Publicaciones Editar " + appname },
  },
  {
    path: "/publicacion/detalle/:id",
    name: "PublicacionesDetail",
    component: PublicacionesDetail,
    meta: { title: "Publicaciones Detalle " + appname },

  },
  {
    path: "/inspirarte/solicitudes",
    name: "Solicitudes",
    component: Solicitudes,
    meta: { title: "Inspirarte " + appname },
  }, 
  {
    path: "/inspirarte",
    name: "Inspirarte",
    component: Inspirarte,
    meta: { title: "Inspirarte " +   appname },
  },
  {
    path: "/inspirarte/agregar",
    name: "InspirarteAdd",
    component: InspirarteAdd,
    meta: { title: "Inspirarte Registrar " + appname },
  },
  {
    path: "/inspirarte/editar/:id",
    name: "InspirarteEdit",
    component: InspirarteEdit,
    meta: { title: "Inspirarte Editar " + appname },
  },
  {
    path: "/inspirarte/detalle/:id",
    name: "InspirarteDetail",
    component: InspirarteDetail,
    meta: { title: "Inspirarte Detalle " + appname },

  },
  {
    path: "/inspirarte/solicitudes",
    name: "InspirarteSolicitudes",
    component: InspirarteSolicitudes,
    meta: { title: "Inspirarte Solicitudes " +   appname },
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: Contacto,
    meta: { title: "Contactos Registrados " +   appname },
  },
  {
    path: "/contacto/detalle/:id",
    name: "ContactoDetail",
    component: ContactoDetail,
    meta: { title: "Contactos Detalle " + appname },
  },

  {
    path: "/medico",
    name: "Medico",
    component: Medico,
    meta: { title: "Médicos " +   appname },
  },
  {
    path: "/medico/detalle/:id",
    name: "MedicoDetail",
    component: MedicoDetail,
    meta: { title: "Médico Detalle " + appname },
  },
  {
    path: "/medico/agregar",
    name: "MedicoAdd",
    component: MedicoAdd,
    meta: { title: "Médico Registrar " + appname },
  },
  {
    path: "/medico/editar/:id",
    name: "MedicoEdit",
    component: MedicoEdit,
    meta: { title: "Médico Editar " + appname },
  },


  {
    path: "/testimonio",
    name: "Testimonio",
    component: Testimonio,
    meta: { title: "Testimonios " +   appname },
  },
  {
    path: "/testimonio/detalle/:id",
    name: "TestimonioDetail",
    component: TestimonioDetail,
    meta: { title: "Testimonio Detalle " + appname },
  },
  {
    path: "/testimonio/agregar",
    name: "TestimonioAdd",
    component: TestimonioAdd,
    meta: { title: "Testimonio Registrar " + appname },
  },
  {
    path: "/testimonio/editar/:id",
    name: "TestimonioEdit",
    component: TestimonioEdit,
    meta: { title: "Testimonio Editar " + appname },
  },

  {
    path: "/ubicacion/pais",
    name: "Pais",
    component: Pais,
    meta: { title: "País " +   appname },
  },
  {
    path: "/ubicacion/pais/agregar",
    name: "PaisAdd",
    component: PaisAdd,
    meta: { title: "País Registrar " + appname },
  },
  {
    path: "/ubicacion/pais/editar/:id",
    name: "PaisEdit",
    component: PaisEdit,
    meta: { title: "País Editar " + appname },
  },


  {
    path: "/ubicacion/estado",
    name: "Estado",
    component: Estado,
    meta: { title: "Estado " +   appname },
  },
  {
    path: "/ubicacion/estado/agregar",
    name: "EstadoAdd",
    component: EstadoAdd,
    meta: { title: "Estado Registrar " + appname },
  },
  {
    path: "/ubicacion/estado/editar/:id",
    name: "EstadoEdit",
    component: EstadoEdit,
    meta: { title: "Estado Editar " + appname },
  },

  {
    path: "/ubicacion/municipio",
    name: "Municipio",
    component: Municipio,
    meta: { title: "Municipio " +   appname },
  },
  {
    path: "/ubicacion/municipio/agregar",
    name: "MunicipioAdd",
    component: MunicipioAdd,
    meta: { title: "Municipio Registrar " + appname },
  },
  {
    path: "/ubicacion/municipio/editar/:id",
    name: "MunicipioEdit",
    component: MunicipioEdit,
    meta: { title: "Municipio Editar " + appname },
  },

  {
    path: "/ubicacion/parroquia",
    name: "Parroquia",
    component: Parroquia,
    meta: { title: "Parroquia " +   appname },
  },
  {
    path: "/ubicacion/parroquia/agregar",
    name: "ParroquiaAdd",
    component: ParroquiaAdd,
    meta: { title: "Parroquia Registrar " + appname },
  },
  {
    path: "/ubicacion/parroquia/editar/:id",
    name: "ParroquiaEdit",
    component: ParroquiaEdit,
    meta: { title: "Parroquia Editar " + appname },
  },

  {
    path: "/ubicacion/ciudad",
    name: "Ciudad",
    component: Ciudad,
    meta: { title: "Ciudad " +   appname },
  },
  {
    path: "/ubicacion/ciudad/agregar",
    name: "CiudadAdd",
    component: CiudadAdd,
    meta: { title: "Ciudad Registrar " + appname },
  },
  {
    path: "/ubicacion/ciudad/editar/:id",
    name: "CiudadEdit",
    component: CiudadEdit,
    meta: { title: "Ciudad Editar " + appname },
  },


  {
    path: "/medicamento",
    name: "Medicamento",
    component: Medicamento,
    meta: { title: "Medicamentos " +   appname },
  },
  {
    path: "/medicamento/agregar",
    name: "MedicamentoAdd",
    component: MedicamentoAdd,
    meta: { title: "Medicamento Registrar " + appname },
  },
  {
    path: "/medicamento/detalle/:id",
    name: "MedicamentoEdit",
    component: MedicamentoEdit,
    meta: { title: "Medicamento Editar " + appname },

  },
  {
    path: "/medicamento/editar/:id",
    name: "MedicamentoDetail",
    component: MedicamentoDetail,
    meta: { title: "Medicamento Detalle " + appname },
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path != from.path) {
       return { top: 0 };
    }
 },
  linkExactActiveClass: "exact-active",
});

const getUser = async () => {
  let user = await api.get('/user')
  .then((res) => {
      //console.log(res);
      if(res.status != 200){
          global.loading = false
      }
      if(res.status == 200){
          const loggedUser = useLoggedUser();
          loggedUser.setLoggedUser(res.data);
          const global = useGlobal();
          global.loading = false;
      }
  })
  .catch((err) => {
      const global = useGlobal()
      global.loading = false
      global.setNetworkError(true)
  });
}

router.beforeEach(async (to, from, next) => {
  window.progress.start();

  const loggedUser = useLoggedUser();


  //Se agregan las pagina que no requieren de incio  de sesssion
const paginas =['Login','ForgotPassword','Construccion','Inicio',
'Descubrenos','Donaciones','Cuidadores','Informate','ArticleDetalle','InspiracionDetalle','Investigacion','Salud','Inspiracion','ArteTerapia','AutoCuidado','Paciente','Bienestar','Comunicarte','Registro',
'Page404','Page500','PageMaintenance','PoliticaPrivacidad','DonacionesBuscar','DonacionesMedicamentos','DonacionesVoluntarios','IntercambioSaludable'];

   if (!loggedUser.loggedUser && !paginas.includes(to.name)) {
      await getUser();
  } 

  if ( !paginas.includes(to.name) && !loggedUser.loggedUser ) {
      next({ name: 'Login' });
  } else {
      if ( to.name == 'Login' && loggedUser.loggedUser ) {
          next({ name: 'Dashboard' });
      } else {
          document.title = to.meta.title;
          next();
      }
  }
});

router.afterEach(async (to, from) => {
  window.progress.finish();

  await nextTick();

  let preserveSateKeys = ['back', 'current', 'forward', 'replaced', 'position', 'scroll'];
  let stateKeys = Object.keys(window.history.state);
  for(let key of stateKeys){
      if(!preserveSateKeys.includes(key)){
          delete window.history.state[key];
      }
  }
});

router.onError(() => {
  window.progress.fail();
});

export default router;
