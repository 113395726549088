<template>
      <router-view></router-view>

      <vue-progress-bar></vue-progress-bar>
<!--   <NetworkError v-if="global.networkError && false" :error="global.networkError" />

 -->
</template>

<script setup>
import { ref, watch, onMounted,inject } from "vue";
import { useRouter, useRoute  } from "vue-router";
  //import NetworkError from "./views/layouts/error/NetworkError.vue";
const router = useRouter();
const route = useRoute();
//route.meta.hideNavFoot = true;
//Prototypes


import { getCurrentInstance } from "vue";

window.internalInstance = getCurrentInstance();
window.progress = window.internalInstance.appContext.config.globalProperties.$Progress;



String.prototype.ucwords = function () {
  let str = this.toLowerCase();
  return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
    function (s) {
      return s.toUpperCase();
    });
};
//End prototypes





/* onMounted(() => {
  progress.finish();
});

onBeforeRouteEnter((to, from, next) => {
  progress.start();
  next();
});

onBeforeRouteLeave((to, from, next) => {
  progress.finish();
  next();
});

 */


</script>

<style lang="postcss"></style>
<style>
.blueimp-gallery > .next, .blueimp-gallery > .prev{
  border: none !important;
} 
</style>